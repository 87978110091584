'use client';

import { styled } from '@mui/material';
import { MaterialDesignContent, SnackbarProvider } from 'notistack';
const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent': {
    borderRadius: '8px'
  },
  '&.notistack-MuiContent-default': {
    backgroundColor: '#323232'
  }
}));
export default ({
  children
}: {
  children: React.ReactNode;
}) => <SnackbarProvider Components={{
  default: StyledMaterialDesignContent
}}>
    {children}
  </SnackbarProvider>;