'use client';

import type { ReactNode } from 'react';
import type { AbstractIntlMessages } from 'next-intl';
import { NextIntlClientProvider } from 'next-intl';
import { PermissionsProvider } from '@ta-authority/constraint-components';
import { SessionProvider } from 'next-auth/react';
import { ConfirmProvider } from 'material-ui-confirm';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import ReactQueryProvider from './ReactQueryProvider';
import ThemeProvider from './ThemeProvider';
import ProvideTenantSettings from './TenantSettings/provider';
import SnackbarProvider from './SnackbarProvider';
type Props = {
  children: ReactNode;
  locale: string;
  messages?: AbstractIntlMessages | undefined;
  permissions?: any[];
};
export default function Provider({
  children,
  locale,
  messages,
  permissions = []
}: Props) {
  return <ReactQueryProvider data-sentry-element="ReactQueryProvider" data-sentry-component="Provider" data-sentry-source-file="Provider.tsx">
      <NextIntlClientProvider locale={locale} messages={messages} timeZone="UTC" data-sentry-element="NextIntlClientProvider" data-sentry-source-file="Provider.tsx">
        <LocalizationProvider dateAdapter={AdapterDayjs} data-sentry-element="LocalizationProvider" data-sentry-source-file="Provider.tsx">
          <ThemeProvider data-sentry-element="ThemeProvider" data-sentry-source-file="Provider.tsx">
            <SnackbarProvider data-sentry-element="SnackbarProvider" data-sentry-source-file="Provider.tsx">
              <ConfirmProvider defaultOptions={{
              dialogProps: {
                maxWidth: 'xs'
              },
              cancellationButtonProps: {
                size: 'small',
                variant: 'outlined'
              },
              confirmationButtonProps: {
                size: 'small'
              }
            }} data-sentry-element="ConfirmProvider" data-sentry-source-file="Provider.tsx">
                <PermissionsProvider ACL_FALLBACK_RULE="deny" preparedPermissions={permissions} data-sentry-element="PermissionsProvider" data-sentry-source-file="Provider.tsx">
                  <SessionProvider data-sentry-element="SessionProvider" data-sentry-source-file="Provider.tsx">
                    <ProvideTenantSettings data-sentry-element="ProvideTenantSettings" data-sentry-source-file="Provider.tsx">{children}</ProvideTenantSettings>
                  </SessionProvider>
                </PermissionsProvider>
              </ConfirmProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </NextIntlClientProvider>
    </ReactQueryProvider>;
}