import { createContext } from 'react';

export interface Timezone {
  id: string;
  iana: string;
  customLabel?: string;
}

export interface ExtendedTimezone extends Timezone {
  name: string;
  aliasOf?: string;
}

interface DefaultState {
  timezones: ExtendedTimezone[];
}

const defaultState = {
  timezones: [],
};

const tenantSettingsContext = createContext<DefaultState>(defaultState);

export default tenantSettingsContext;
