'use client';

import Script from 'next/script';
function GTagAnalyticsHeader() {
  return <>
      <Script src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_GTAG_ID}`} strategy="afterInteractive" data-sentry-element="Script" data-sentry-source-file="GTagAnalytics.tsx" />
      <Script id="gtag-init" strategy="afterInteractive" dangerouslySetInnerHTML={{
      __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_GTAG_ID}', {
              page_path: window.location.pathname,
            });
          `
    }} data-sentry-element="Script" data-sentry-source-file="GTagAnalytics.tsx" />
    </>;
}
function GTagAnalyticsBody() {
  return <noscript data-sentry-component="GTagAnalyticsBody" data-sentry-source-file="GTagAnalytics.tsx">
      <iframe title="gtag" src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_GTAG_ID}`} height="0" width="0" style={{
      display: 'none',
      visibility: 'hidden'
    }} />
    </noscript>;
}
const sendGTag = (tagName: string, data: Record<string, any> = {}) => {
  if (typeof window === 'undefined') {
    console.warn('sendGTag: window is not defined.');
    return;
  }
  if (typeof window.gtag !== 'function') {
    console.warn('sendGTag: GTag is not initialized.');
    return;
  }
  try {
    window.gtag('event', tagName, data);
  } catch (error) {
    console.error('sendGTag: Failed to send event.', error);
  }
};
export { GTagAnalyticsHeader, GTagAnalyticsBody, sendGTag };