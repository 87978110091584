'use client';

import ct from 'countries-and-timezones';
import React from 'react';
import * as Sentry from '@sentry/nextjs';
import { momentTzList } from '../../TimezoneSelector/helpers';
import tenantSettingsContext from './context';
import api from '@_/src/lib/api';
function ProvideTenantSettings({
  children
}: {
  children: React.ReactNode;
}) {
  const [timezones, set$timezones] = React.useState([]);
  const fetchTimezones = async () => {
    try {
      const fetchedTimezones = await api.get(`/api/v1/tenant/${process.env.NEXT_PUBLIC_TENANT_ID}/timezones`);
      if (fetchedTimezones.data) {
        return fetchedTimezones.data;
      }
      return momentTzList;
    } catch (error) {
      Sentry.captureException(error);
      return momentTzList;
    }
  };
  const prepareTimezones = async () => {
    try {
      const fetchedTimezones = await fetchTimezones();
      const extendedDataTimezones = fetchedTimezones.map((tz: any) => ({
        ...ct.getTimezone(tz?.iana || tz),
        name: tz?.iana || tz,
        ...(typeof tz === 'object' ? tz : {})
      }));
      const sortedTimezones = extendedDataTimezones.filter((tz: any) => !tz.name.includes('GMT'));
      set$timezones(sortedTimezones);
    } catch (error) {
      Sentry.captureException(error);
    }
  };
  React.useEffect(() => {
    prepareTimezones();
  }, []);
  return <tenantSettingsContext.Provider
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  value={{
    timezones
  }} data-sentry-element="unknown" data-sentry-component="ProvideTenantSettings" data-sentry-source-file="provider.tsx">
      {children}
    </tenantSettingsContext.Provider>;
}
export default ProvideTenantSettings;