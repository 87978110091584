import React from 'react';
import { AppRouterCacheProvider } from '@mui/material-nextjs/v14-appRouter';
import ThemeRegistry from '@/theme/ThemeRegistry';
import 'react-phone-input-2/lib/material.css';
type Props = {
  children: React.ReactNode;
};
export default function ThemeProvider({
  children
}: Props) {
  return <AppRouterCacheProvider data-sentry-element="AppRouterCacheProvider" data-sentry-component="ThemeProvider" data-sentry-source-file="ThemeProvider.tsx">
      <ThemeRegistry options={{
      key: 'mui-theme'
    }} data-sentry-element="ThemeRegistry" data-sentry-source-file="ThemeProvider.tsx">{children}</ThemeRegistry>
    </AppRouterCacheProvider>;
}