'use client';

import Script from 'next/script';
function ClarityAnalytics() {
  return <Script id="clarity-microsoft" type="text/javascript" data-sentry-element="Script" data-sentry-component="ClarityAnalytics" data-sentry-source-file="ClarityAnalytics.tsx">
      {`
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "${process.env.NEXT_PUBLIC_CLARITY_ID}");
    `}
    </Script>;
}
const sendClarityTag = (tagName: string, data: Record<string, any> = {}) => {
  if (typeof window === 'undefined') {
    console.warn('sendClarityTag: window is not defined (likely running on the server).');
    return;
  }
  if (typeof window.clarity !== 'function') {
    console.warn('sendClarityTag: Clarity is not initialized or not available.');
    return;
  }
  try {
    window.clarity('set', tagName, JSON.stringify(data));
  } catch (error) {
    console.error('sendClarityTag: Failed to send tag to Clarity.', error);
  }
};
export { ClarityAnalytics, sendClarityTag };