'use client';

import { Roboto } from 'next/font/google';
import type { ThemeOptions } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';
const roboto = Roboto({
  weight: ['300', '400', '500', '700'],
  subsets: ['latin'],
  display: 'swap'
});
export const defaultColumnDef = {
  filter: false,
  editable: false,
  disableReorder: true,
  pinnable: false,
  sortable: true
};
export const themeConfig: ThemeOptions = {
  palette: {
    text: {
      primary: '#171A1C',
      secondary: '#4F5152BF'
    },
    primary: {
      main: '#593E95'
    },
    secondary: {
      main: '#F5F1FF'
    },
    info: {
      main: '#0288D1'
    },
    error: {
      main: '#C41C1C'
    },
    action: {
      selected: '#593E9514'
    },
    divider: '#E0E0E0'
  },
  typography: {
    fontFamily: roboto.style.fontFamily,
    caption: {
      color: '#555E68'
    },
    subtitle1: {
      fontWeight: 500
    }
  },
  components: {
    MuiDataGrid: {
      defaultProps: {
        disableColumnMenu: true,
        pagination: true,
        disableColumnSelector: true,
        pageSizeOptions: [10, 25, 50],
        paginationMode: 'server',
        sortingMode: 'server'
      },
      styleOverrides: {
        root: {
          border: '1px solid var(--elevation-outlined, #E0E0E0)',
          padding: 8,
          borderRadius: 8
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        variant: 'outlined',
        elevation: 0
      },
      styleOverrides: {
        root: {
          borderRadius: 8
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontWeight: 600,
          fontSize: '28px',
          lineHeight: '36px'
        },
        h2: {
          fontWeight: 600,
          fontSize: '24px',
          lineHeight: '32px'
        },
        h3: {
          fontWeight: 600,
          fontSize: '18px',
          lineHeight: '24px'
        },
        h4: {
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '20px'
        },
        body1: {
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '24px'
        },
        caption: {
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '16px'
        }
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        color: 'primary',
        disableElevation: true
      },
      styleOverrides: {
        root: {
          borderRadius: '8px',
          padding: '8px 16px',
          textTransform: 'none',
          whiteSpace: 'nowrap',
          '&.MuiButton-contained': {
            '&.Mui-disabled': {
              opacity: 0.3,
              color: 'var(--variant-containedColor)',
              backgroundColor: 'var(--variant-containedBg)'
            }
          }
        },
        sizeSmall: {
          padding: '6px 16px'
        }
        // outlined: {
        //   border: '1px solid #E0E0E0',
        //   '&:hover': {
        //     border: '1px solid #E0E0E0',
        //   },
        // },
        // outlinedPrimary: {
        //   border: '1px solid #593E95',
        // },
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeSmall: {
          fontSize: 16
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px'
          }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '8px'
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '16px!important'
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        title: {
          fontSize: '18px',
          fontWeight: 500,
          fontStyle: 'normal',
          lineHeight: '160%',
          letterSpacing: '0.15px'
        },
        subheader: {
          fontSize: '16px',
          fontWeight: 400,
          fontStyle: 'normal',
          lineHeight: '160%'
        }
      }
    },
    // MuiAvatarGroup: {
    //   styleOverrides: {
    //     root: ({ ownerState: { max } }) => ({
    //       ...[...Array(max)].reduce(
    //         (result, curr, index) => ({
    //           ...result,
    //           [`& > .MuiAvatar-root:nth-child(${index + 1})`]: {
    //             zIndex: !max ? index : max - index,
    //           },
    //         }),
    //         {}
    //       ),
    //     }),
    //   },
    // },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '8px'
        },
        outlined: {
          borderColor: 'inherit',
          color: 'inherit'
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 52,
          height: 32,
          padding: 0,
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: '4px',
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(20px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                border: 'none',
                opacity: 1
              },
              '& .MuiSwitch-thumb': {
                color: '#FFF',
                border: 'none'
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5
              }
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
              color: '#33cf4d',
              border: '6px solid #fff'
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              color: '#C1C1C1'
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.7
            }
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 24,
            height: 24,
            border: '4px solid #F9F9F9',
            color: '#4F5152',
            boxShadow: 'none'
          },
          '& .MuiSwitch-track': {
            borderRadius: 32 / 2,
            backgroundColor: '#F9F9F9',
            border: '1px solid #C1C1C1',
            opacity: 1
          }
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          border: 'none',
          borderRadius: '8px'
        }
      }
    },
    MuiDialog: {
      defaultProps: {
        fullWidth: true,
        maxWidth: 'xs'
      },
      styleOverrides: {
        paper: {
          border: 'none',
          borderRadius: '8px'
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '18px',
          lineHeight: '28px',
          fontWeight: 500,
          padding: '24px 24px 0px 24px'
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          // TODO: remove important
          padding: '16px 24px 16px 24px !important'
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '8px 24px 24px 24px;'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          font: '14px',
          fontWeight: 500,
          lineHeight: '24px',
          textTransform: 'capitalize'
        },
        textColorPrimary: {
          color: '#32383E'
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: '8px 16px',
          borderRadius: '8px',
          '&.Mui-selected': {
            background: '#593E9514'
          },
          '&.Mui-selected > .MuiListItemIcon-root': {
            color: '#593E95'
          }
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '40px',
          padding: '0 16px 0 0 '
        }
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  }
};
export default createTheme(themeConfig);