'use client';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
type Props = {
  children: React.ReactNode;
};
export default function ReactQueryProvider({
  children
}: Props) {
  const [queryClient] = React.useState(() => new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        // With SSR, we usually want to set some default staleTime
        // above 0 to avoid refetching immediately on the client
        staleTime: 4 * 1000
        // refetchInterval: 4 * 1000,
      }
    }
  }));
  return <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-component="ReactQueryProvider" data-sentry-source-file="ReactQueryProvider.tsx">
      {children}
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>;
}