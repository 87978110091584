'use client';

import { useState } from 'react';
import type { Options } from '@emotion/cache';
import createCache from '@emotion/cache';
import { useServerInsertedHTML } from 'next/navigation';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
export default function ThemeRegistry(props: {
  options: Options;
  children: React.ReactNode;
}) {
  const {
    options,
    children
  } = props;
  const [{
    cache,
    flush
  }] = useState(() => {
    const newCache = createCache(options);
    newCache.compat = true;
    const prevInsert = newCache.insert;
    let inserted: string[] = [];
    newCache.insert = (...args) => {
      const serialized = args[1];
      if (newCache.inserted[serialized.name] === undefined) {
        inserted.push(serialized.name);
      }
      return prevInsert(...args);
    };
    const newFlush = () => {
      const prevInserted = inserted;
      inserted = [];
      return prevInserted;
    };
    return {
      cache: newCache,
      flush: newFlush
    };
  });
  useServerInsertedHTML(() => {
    const names = flush();
    if (names.length === 0) {
      return null;
    }
    const styles = names.reduce((prev, next) => {
      if (typeof cache.inserted[next] === 'boolean') {
        return prev;
      }
      return `${prev}${cache.inserted[next]}`;
    }, '');
    return <style key={cache.key} data-emotion={`${cache.key} ${names.join(' ')}`}
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: styles
    }} />;
  });
  return <CacheProvider value={cache} data-sentry-element="CacheProvider" data-sentry-component="ThemeRegistry" data-sentry-source-file="ThemeRegistry.tsx">
      <ThemeProvider theme={theme} data-sentry-element="ThemeProvider" data-sentry-source-file="ThemeRegistry.tsx">
        <CssBaseline data-sentry-element="CssBaseline" data-sentry-source-file="ThemeRegistry.tsx" />
        {children}
      </ThemeProvider>
    </CacheProvider>;
}