import moment from 'moment';

import 'moment-timezone';
import type { ExtendedTimezone } from '../provider/TenantSettings/context';

interface IGetTzName {
  format?:
    | 'short'
    | 'long'
    | 'shortOffset'
    | 'longOffset'
    | 'shortGeneric'
    | 'longGeneric'
    | undefined;
  timeZone: string;
}

export const getTimeZoneName = ({ timeZone, format }: IGetTzName) => {
  try {
    return new Date()
      .toLocaleDateString(undefined, {
        day: '2-digit',
        timeZoneName: format,
        timeZone,
      })
      .substring(4);
  } catch (e) {
    return null;
  }
};

// eslint-disable-next-line default-param-last
export const getTimezoneByName = (timesList = [], tzName: string) => {
  return timesList?.find((t: any) => t.name === tzName);
};

/*
 Pay attention: There is difference between moment.tz.names() and ct.timezoneList()
 But we have to use ct package to determine 2 things: first one is "UTC offset" and the second one
 is "determining correct timezone name" when it has local timezone name, for example: Europe/Lviv or Europe/Zaporozhye.
 There is no Europe/Lviv or Europe/Zaporozhye timezones in the world, we have to use default timezone for Ukraine - Europe/Kiev
*/
export const momentTzList = moment.tz.names();

export const replaceLongTzName = (name: string) => {
  if (!name) return '';

  let shortName = name || '';
  shortName = name.replace('Eastern European Standard Time', 'Eastern Time');
  shortName = name.replace('Alaska Standard Time', 'Alaska Time');
  shortName = name.replace('Pacific Standard Time', 'Pacific Time');

  return shortName;
};

export const getTimezoneSelectLabel = (timezone: any) => {
  const timezoneName = timezone?.name;
  const tzFullName = getTimeZoneName(timezoneName);
  const hasSlash = timezoneName.includes('/');
  const tzNameHasGMT = tzFullName?.includes('GMT');
  const splittedTzName = timezoneName.split('/');
  const tzNameWithoutSlash = hasSlash
    ? splittedTzName[splittedTzName.length - 1]
    : timezoneName;

  return `(GMT ${timezone.dstOffsetStr}) ${
    !tzNameHasGMT ? `${replaceLongTzName(tzFullName || '')} - ` : ''
  } ${tzNameWithoutSlash.replaceAll('_', ' ')}`;
};

export const getRightTimezoneName = (
  name: string,
  timezones: ExtendedTimezone[]
) => {
  if (!name) return '';
  const determinedTimezone = timezones.find(tz => tz.name === name);
  return determinedTimezone?.aliasOf || determinedTimezone?.name;
};
